import React, { PureComponent } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"
import SVG from "react-inlinesvg"
import throttle from "lodash/throttle"

import LogoSVG from "../images/strange-music-logo.svg"
import { Business } from "../utils/constants"

const ConstructionWrapper = styled.div`
  background: linear-gradient(to bottom, #ffffff 0%,#d50001 100%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

const SocialBar = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 -2px 15px -5px black;
`

const SVGWrapper = styled(SVG)<{ width: number }>`
  & {
    z-index: 0;
    position: absolute;
    bottom: -${props => props.width > 1330 ? 1330/2 : props.width/2}px;

    svg {
      width: ${props => props.width}px;
      max-width: 1130px;
      height: auto;
      fill: rgba(0,0,0,0.1);
    }
  }
`

const ComingSoonText = styled.p`
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
  width: min-content;
`

const ContactAreaWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media(max-width: 768px) {
    flex-direction: column-reverse;
    bottom: 20px;
  }
`

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactAreaText = styled.p`
  color: white;
  font-weight: 300;
  margin: 0 20px;
  font-size: 14px;
`

const ContactAreaSpacer = styled(ContactAreaText)`
  display: block;

  @media(max-width: 768px) {
    display: none;
  }
`

const ContactAreaLink = styled.a`
  color: white;
  font-weight: 300;
  margin: 0 20px;

  &:hover {
    color: rgba(255,255,255,0.8)
  }
`

export default class UnderConstruction extends PureComponent<{}, { width: number }> {
  constructor(props: {}) {
    super(props)

    this.updateWidth = throttle(this.updateWidth.bind(this))

    this.state = {
      width: typeof window !== "undefined" ? window.innerWidth : 0
    }
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth)
  }

  render() {
    return(
      <ConstructionWrapper>
        <SocialBar>
          <a href={Business.facebook} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon 
                icon={faFacebookF} 
                color="black"
                style={{width: "23px", height: "23px"}}/>
          </a>
          <a href={Business.twitter} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon 
                icon={faTwitter} 
                color="black"
                style={{width: "25px", height: "25px"}}/>
          </a>
          <a href={Business.instagram} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon 
                icon={faInstagram} 
                color="black"
                style={{width: "25px", height: "25px"}}/>
          </a>
          <a href={Business.youtube} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon 
                icon={faYoutube} 
                color="black"
                style={{width: "25px", height: "22px"}}/>
          </a>
        </SocialBar>
        <ComingSoonText>Coming Soon</ComingSoonText>
        <ContactAreaWrapper>
            <AddressWrapper>
                <ContactAreaText>{Business.address}</ContactAreaText>
                <ContactAreaText>{Business.city}, {Business.state} {Business.zip}</ContactAreaText>
            </AddressWrapper>
            <ContactAreaSpacer>|</ContactAreaSpacer>
            <ContactAreaLink href={`tel:${Business.phoneNumber}`}>{Business.phoneNumber}</ContactAreaLink>
            <ContactAreaSpacer>|</ContactAreaSpacer>
            <ContactAreaLink href={`mailto:${Business.email}`}>{Business.email}</ContactAreaLink>
        </ContactAreaWrapper>
        <SVGWrapper src={LogoSVG} width={this.state.width}/>
      </ConstructionWrapper>
    )
  }
}