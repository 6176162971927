import React, { CSSProperties, ReactNode } from "react"
import styled from "styled-components"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import SVG from 'react-inlinesvg'

import Theme from "../../theme"
import Logo from "../../images/strange-music-logo.svg"
import { Omit } from "lodash"

interface SectionProps extends CSSProperties {
    id?: string,
    themeColor?: string,
    children?: any
}

const SectionWrapper = styled.div<SectionProps>`
    display: flex;
    margin: ${props => props.margin ? props.margin : "20px 0"};
    padding: ${props => props.padding ? props.padding: "initial"};
    flex-direction: ${props => props.flexDirection ? props.flexDirection : "row"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "initial"};
    align-items: ${props => props.alignItems ? props.alignItems : "initial"};
    align-self: ${props => props.alignSelf ? props.alignSelf : "initial"};
    width: ${props => props.width ? props.width : "auto"};
    height: ${props => props.height ? props.height : "auto"};
    background: ${props => props.background ? props.background : props.themeColor ? Theme.palette.primary.main : "transparent"};
    max-width: ${props => props.maxWidth ? props.maxWidth : "initial"};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : "nowrap"};
    overflow: ${props => props.overflow ? props.overflow : "unset"};
`

const Section = (props: SectionProps) => (
    <SectionWrapper {...props}>
        {props.children}
    </SectionWrapper>
)

export default Section



const SubSectionWrapper = styled.div<SectionProps>`
    position: relative;
    width: 70vw;
    margin: ${props => props.margin ? props.margin : "0 auto"};
    background: ${props => props.themeColor ? Theme.palette.primary.main : "transparent"};

    @media(max-width: 1260px) {
        width: 90vw;
    }
`

export const SubSection = (props: SectionProps) => (
    <SubSectionWrapper {...props}>
        {props.children}
    </SubSectionWrapper>
)

interface HeaderProps extends Omit<SectionProps, "color">, Omit<TypographyProps, "children"> {}

const TitleWrapper = styled(Typography)<HeaderProps>`
    &&& {
        margin: ${props => props.margin ? props.margin : "40px auto"};
        font-weight: bold;
        text-align: center;
        letter-spacing: 3px;
        color: ${props => props.themeColor ? "white" : "black"};
    }
`

export const Header = (props: HeaderProps) => (
    <TitleWrapper variant="h5" {...props}>
        {props.children}
    </TitleWrapper>
)

const SubHeaderWrapper = styled(Typography)<HeaderProps>`
    &&& {
        font-weight: 300;
        max-width: ${props => props.maxWidth ? props.maxWidth : "90%"};
        text-align: ${props => props.textAlign ? props.textAlign : "center"};
        margin: 0 auto;
        color: ${props => props.themeColor ? "white" : "black"};

        @media(max-width: 768px) {
            max-width: 70%;
            text-align: ${props => props.textAlign ? props.textAlign : "justify"}
        }
    }
`

export const SubHeader = (props: HeaderProps) => {
    return(
        <SubHeaderWrapper 
            {...props}
            variant="body1">
            {props.children}
        </SubHeaderWrapper>
    )
}

const DividerOutterWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;
`

const DividerInnerWrapper = styled.div<SectionProps>`
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    align-items: center;
    width: ${props => props.width ? props.width : "auto"};
`

const DividerLine = styled.div<SectionProps>`
    height: 1px;
    width: 100px;
    background: ${props => props.themeColor ? Theme.palette.primary.main : "white"};
`

const LogoWrapper = styled(SVG)<SectionProps>`
    & {
        svg {
            width: 35px;
            height: 35px;
            margin: 20px;
            fill: ${props => props.themeColor ? props.themeColor : "white"}
        }
    }
`

export const Divider = (props: SectionProps) => {
    return(
        <DividerOutterWrapper>
            <DividerInnerWrapper {...props}>
                <DividerLine {...props}/>
                <LogoWrapper src={Logo} {...props} />
                <DividerLine {...props}/>
            </DividerInnerWrapper>
        </DividerOutterWrapper>
    )
}