import React, { PureComponent, ReactNode } from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import { MuiThemeProvider } from "@material-ui/core/styles"
import MessengerCustomerChat from "react-messenger-customer-chat"

import Header from "./header"
import Footer from "./footer"
import Theme from "../theme"
import Logo from "../images/snake-and-bat-red.png"
import UnderConstruction from "./underConstruction"
import { Business } from "../utils/constants"
// import MSTileIcon from "../images/icons/mstile-144x144.png";
// import AppleIcon57 from "../images/icons/apple-touch-icon-57x57.png";
// import AppleIcon114 from "../images/icons/apple-touch-icon-114x114.png";
// import AppleIcon72 from "../images/icons/apple-touch-icon-72x72.png";
// import AppleIcon144 from "../images/icons/apple-touch-icon-144x144.png";
// import AppleIcon120 from "../images/icons/apple-touch-icon-120x120.png";
// import AppleIcon152 from "../images/icons/apple-touch-icon-152x152.png";
// import Favicon32 from "../images/icons/favicon-32x32.png";
// import Favicon16 from "../images/icons/favicon-16x16.png";

import "./layout.css"
import { WindowLocation } from "@reach/router";

const Main = styled.div`
  background: white;
  margin-top: 100px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px) {
    margin-top: 56px;
  }
`

const PhoneButton = styled(Button)`
  &&& {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    color: black;
  }
`

interface LayoutPageProps {
  location: WindowLocation
}

export default class Layout extends PureComponent<LayoutPageProps, {}> {
  render() {
    return(
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data: any): ReactNode => (
          <>
            <MuiThemeProvider theme={Theme}>
              {/* <Header/> */}
              <Main>
                <Helmet
                  title={data.site.siteMetadata.title}
                  meta={[
                    { name: "description", content: "" },
                    { name: "keywords", content: "" },
                    { name: "content-type", content: "text/html, charset=utf-8"},
                    { name: "viewport", content: "width=device-width, initial-scale=1"}
                  ]}
                  link={[
                    { "href": "https://fonts.googleapis.com/icon?family=Material+Icons", "rel":"stylesheet" },
                    // { href: AppleIcon57, rel: "apple-touch-icon-precomposed", sizes: "57x57" },
                    // { href: AppleIcon114, rel: "apple-touch-icon-precomposed", sizes: "114x114" },
                    // { href: AppleIcon72, rel: "apple-touch-icon-precomposed", sizes: "72x72" },
                    // { href: AppleIcon144, rel: "apple-touch-icon-precomposed", sizes: "144x144" },
                    // { href: AppleIcon120, rel: "apple-touch-icon-precomposed", sizes: "120x120" },
                    // { href: AppleIcon152, rel: "apple-touch-icon-precomposed", sizes: "152x152" },
                    // { href: Favicon32, rel: "icon", type: "image/png", sizes: "32x32" },
                    // { href: Favicon16, rel: "icon", type: "image/png", sizes: "16x16" }
                  ]}
                  script={[
                    { src: "https://www.google.com/recaptcha/api.js", type: "text/javascript" },
                  ]}>
                  <script type="application/ld+json">{`{
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "url": "${Business.url}",
                    "logo": ${Logo},
                    "name": "${Business.name}",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+1${Business.phoneNumber}",
                      "contactType": "Customer service"
                    },
                    "sameAs": [
                      "${Business.facebook}",
                      "${Business.twitter}",
                      "${Business.instagram}",
                      "${Business.youtube}"
                    ]
                  }`}</script>
                </Helmet>
              {<UnderConstruction/>}
              </Main>
              {/* <Footer /> */}
              <MessengerCustomerChat
                pageId=""
                appId=""
                htmlRef="fb-messenger-chat"
                version="2.12"
                themeColor="#ffc300"/>
              <div id="modal-root" />
            </MuiThemeProvider>
          </>
        )}
      />
    )
  }
}