export const Business = Object.freeze({
    name: "The Wash KC",
    address: "1201 NW Sloan St",
    city: "Lees Summit",
    state: "MO",
    zip: "64086",
    phoneNumber: "(816) 347-9274",
    email: "info@thewashkc.com",
    url: "https://www.t.thewashkc.com",
    facebook: "https://www.facebook.com/The-Wash-KC-174969963423997/",
    twitter: "https://twitter.com/thewashkc",
    instagram: "https://www.instagram.com/thewashkc/",
    youtube: "https://www.youtube.com/channel/UCYzGDrqYmGneEWngATzzYcQ"
})